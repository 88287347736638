import React from 'react';

import { CardFlat } from 'core/entities/flats';

import { useModal } from 'components/common/modal/modal';
import { FlatPositionModal } from 'components/flat/flat-position/flat-position';

interface Rating {
  rating_points: number;
  rating_points_total: number;
}

interface FlatPositionInlineProps {
  rating?: Rating;
  className: string;
  flat: CardFlat;
  mainHost: string;
}

export function FlatPositionInline({ rating, className, flat, mainHost }: FlatPositionInlineProps) {
  if (!rating) {
    return null;
  }
  const { opened, onOpen, onClose } = useModal(false);
  return (
    <>
      <div className={className} onClick={onOpen}>
        <div>
          <span>Баллы:</span>
          <span>{rating.rating_points}</span>
        </div>
        <div>
          <span>Всего баллы:</span>
          <span>{rating.rating_points_total}</span>
        </div>
      </div>
      {opened && <FlatPositionModal flat={flat} opened={opened} onClose={onClose} mainHost={mainHost} />}
    </>
  );
}
