import styles from './flat-position.module.css';

import { RatingInfo } from './flat-position-items';

import classNames from 'classnames';
import React, { useState } from 'react';

import { CardFlat } from 'core/entities/flats';
import { FlatPositionService } from 'core/services/moderation/flat-position';
import { RawPoints } from 'core/utils/flat/flat-position-types';

import { Modal } from 'components/common/modal/modal';
import { Spinner } from 'components/common/spinner/spinner';

interface FlatPositionModalProps {
  flat: CardFlat;
  opened: boolean;
  mainHost: string;
  onClose: () => void;
}

export const FlatPositionModal = ({ flat, opened, onClose, mainHost }: FlatPositionModalProps) => {
  const [data, setData] = useState<RawPoints[]>([]);
  const loaded = React.useRef(false);
  React.useEffect(() => {
    setTimeout(async () => {
      if (loaded.current) {
        return;
      }
      loaded.current = true;
      const service = new FlatPositionService(mainHost);
      const positionData = await service.fetchCaclulcationData(flat.id);

      setData(positionData);
    }, 0);
  }, [loaded.current, opened, mainHost, flat.id]);

  let content = <Spinner size={32} />;
  if (data.length) {
    content = <RatingInfo points={data} />;
  }
  return (
    <Modal
      className={classNames(styles.root)}
      title={`Статистика по №${flat.id} "${flat.address}"`}
      opened={opened}
      onClose={onClose}
    >
      <>
        <div className={styles.content}>{content}</div>
      </>
    </Modal>
  );
};
