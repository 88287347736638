import { LENGTH_LIMIT_REVIEW_MESSAGE } from 'core/constants/settings';

export const REVIEW_HEADING = 'Отзыв о\xa0проживании';
export const REVIEW_SEO_TITLE = 'Отзыв о\xa0проживании — «Квартирка.com»';
export const REVIEW_COMPLETED_HEADING = 'Отзыв опубликован!';

export const REVIEW_TEXT_YANDEX_INSTRUCTION_PART_1 = 'А как вам понравился наш сервис бронирования?';
export const REVIEW_TEXT_YANDEX_INSTRUCTION_PART_2 =
  'Оставьте честный отзыв о\xa0Квартирке в\xa0Яндексе, и\xa0мы\xa0подарим вам ';
export const REVIEW_TEXT_YANDEX_INSTRUCTION_PART_3 = '1000\xa0бонусных рублей';
export const REVIEW_TEXT_YANDEX_INSTRUCTION_PART_4 = ' на\xa0следующую поездку.';
export const REVIEW_TEXT_YANDEX_INSTRUCTION_PART_5 = [
  'Для этого нажмите в\xa0левом верхнем углу окна на\xa0замок, ',
  'затем на\xa0число отзыв, откроется оценка и\xa0потом поле отзыва).'
].join('');
export const REVIEW_TEXT_YANDEX_INSTRUCTION_PART_5_MOB = [
  'Для этого нажмите в\xa0правом нижнем углу экрана на\xa0меню, затем на\xa0адрес сайта ',
  'с\xa0числом отзывов, откроется оценка и\xa0потом поле отзыва.'
].join('');

export const REVIEW_TEXT_INSTRUCTION_THANKS = 'Спасибо';

export const REVIEW_TEXT_LINK_TO_FLAT = 'Посмотреть отзыв в\xa0объявлении';
export const REVIEW_TEXT_LINK_TO_CONTACTS = 'Контакты службы помощи';

export const REVIEW_TEXT_GREAT_INSTRUCTION_TOP_CASHBACK = 'Кешбэк начислен.';
export const REVIEW_TEXT_GREAT_INSTRUCTION_TOP_PART_1 = 'А\xa0как вам понравился наш сервис бронирования?';
export const REVIEW_TEXT_GREAT_INSTRUCTION_TOP_PART_2 =
  'Оставьте честный отзыв о\xa0Квартирке на\xa0одном из\xa0сайтов с\xa0отзывами, и\xa0мы\xa0подарим вам ';
export const REVIEW_TEXT_GREAT_INSTRUCTION_TOP_PART_3 = 'до 5000 бонусных рублей';
export const REVIEW_TEXT_GREAT_INSTRUCTION_TOP_PART_4 =
  ' на\xa0следующие поездки (по\xa01000 бонусов за\xa0каждый сайт из\xa0списка ниже).';

export const REVIEW_TEXT_GREAT_INSTRUCTION_DOWN_PART_1 = [
  'При написании отзывов соблюдайте правила выбранных площадок\xa0и, пожалуйста, ',
  'пишите на\xa0разные сайты отличающиеся тексты.'
].join('');

export const REVIEW_TEXT_GREAT_INSTRUCTION_DOWN_PART_2 = [
  'Для получения бонуса дождитесь, чтобы отзыв прошел модерацию и\xa0был опубликован, ',
  'а\xa0после свяжитесь с\xa0нашей службой помощи: ',
  'пришлите снимок экрана или ссылку на\xa0отзыв и\xa0укажите ваш аккаунт на\xa0Квартирке.'
].join('');

export const REVIEW_TEXT_NORMAL_INSTRUCTION =
  'Спасибо за\xa0ваш отзыв! Он\xa0поможет другим гостям сделать верный выбор.';

export const REVIEW_TEXT_BAD_INSTRUCTION = [
  'Спасибо за\xa0ваш отзыв!',
  [
    'Нам очень жаль, что вы\xa0получили негативный опыт при бронировании жилья у\xa0данного хозяина. ',
    'Его рейтинг будет снижен, а\xa0ваш опубликованный отзыв поможет другим гостям сделать верный выбор.'
  ].join(''),
  'Если мы\xa0что-то можем для вас сделать, напишите нам в\xa0службу помощи.'
];

export const REVIEW_LINK_OTZOVIK_NAME = 'Отзовик';
export const REVIEW_LINK_OTZOVIK_URL =
  'https://otzovik.com/reviews/kvartirka_com-sayt_posutochnoy_arendi_zhilya_v_rossii/';

export const REVIEW_LINK_IRECOMMEND_NAME = 'iRecommend';
export const REVIEW_LINK_IRECOMMEND_URL = 'https://irecommend.ru/content/sait-kvartirkacom-0';

export const REVIEW_LINK_YANDEX_NAME = 'Яндекс.Карты';
export const REVIEW_LINK_YANDEX_URL =
  'https://yandex.ru/maps/org/kvartirka/110192175172/reviews/?ll=61.426269%2C55.160473&z=16';

export const REVIEW_LINK_GOOGLE_NAME = 'Гугл.Карты';
export const REVIEW_LINK_GOOGLE_URL = 'https://g.page/r/CW8bzbzYg2-aEBM/review';

export const REVIEW_LINK_2GIS_NAME = '2ГИС';
export const REVIEW_LINK_2GIS_URL = 'https://2gis.ru/moscow/firm/70000001006977171';

export const REVIEW_TEXT_SUBMIT_SEND = 'Отправить отзыв';
export const REVIEW_TEXT_SUBMIT_SAVE = 'Сохранить изменения';
export const REVIEW_TEXT_FORM_DESCRIPTION = 'Отзыв будет виден хозяину и всем пользователям Квартирки';
export const REVIEW_TEXT_FORM_PLACEHOLDER =
  'Напишите, что вам понравилось и\xa0не\xa0понравилось во\xa0время проживания';

export const REVIEW_TEXT_MESSAGE_LIMIT_WARNING = `Максимальная длина отзыва ${LENGTH_LIMIT_REVIEW_MESSAGE} символов`;

export const RESPONSE_TEXT_VISIBLE_TO_GUEST = 'Отзыв будет виден гостю и всем пользователям Квартирки.';
export const RESPONSE_TEXT_WRITE_ANSWER = 'Напишите ваш ответ';
export const RESPONSE_TEXT_SUBMIT_SEND_ANSWER = 'Отправить ответ';
export const RESPONSE_TEXT_TITLE = 'Ответ на отзыв гостя';
export const RESPONSE_TEXT_CHANGE_ANSWER = 'Исправить ответ';
export const RESPONSE_TEXT_ANSWER_REVIEW = 'Ответить на отзыв';
export const RESPONSE_TEXT_MODIFIED = 'Исправлен';
export const REVIEW_TEXT_YOUR_REVIEW = 'Ваш отзыв';
export const REVIEW_TEXT_CHANGE_REVIEW = 'Изменить отзыв';
export const REVIEW_TEXT_REVIEW_TEXT = 'Отзыв';
export const REVIEW_TEXT_YOUR_ANSWER = 'Ваш ответ';
export const REVIEW_TEXT_WRITE_ANSWER = 'Написать ответ на отзыв';
export const REVIEW_TEXT_CHANGE_ANSWER = 'Изменить ответ';

export const REVIEW_TEXT_GO_TO_APPSTORE = 'Перейти в App Store';
export const REVIEW_TEXT_GO_TO_GOOGLEPLAY = 'Перейти в Google Play';
export const REVIEW_TEXT_GREAT_INSTRUCTION_TOP_PART_2_APPSTORE =
  'Поддержите наш сервис бронирования — напишите честный отзыв о приложении Квартирки в App Store, и мы подарим вам ';
export const REVIEW_TEXT_GREAT_INSTRUCTION_TOP_PART_2_GOOGLEPLAY =
  'Поддержите наш сервис бронирования — напишите честный отзыв о приложении Квартирки в Google Play, и мы подарим вам ';
export const REVIEW_TEXT_GREAT_INSTRUCTION_TOP_PART_3_APPS = '1000 бонусных рублей';
export const REVIEW_TEXT_GREAT_INSTRUCTION_DOWN_APPS = [
  'После оставления отзыва свяжитесь с нашей службой помощи, укажите ',
  'ссылку на отзыв или скриншот, а также ваш аккаунт на Квартирке.'
].join('');
export const REVIEW_AVERAGE_FOR_CITY = 3.5;
export const BEST_ALLOWED_RATING_VALUE = 5;
export const WORST_ALLOWED_RATING_VALUE = 1;
export const MIN_PRICE_FOR_CITY = 1000;
