import { MouseEvent, useCallback, useMemo, useState } from 'react';

import { VisibilityService } from 'core/services/visibility';
import { DescriptionType, sendEvent, serializeDescription } from 'core/utils/metrics';

export interface FlatLink<A = DescriptionType> {
  id: number;
  url: string;
  analytics: A;
}

interface ClickConfig extends FlatLink<string> {
  newTab?: boolean;
  service: VisibilityService;
  activated: boolean;
  setActivated: (next: boolean) => void;
}

const useFlatClick = (conf: ClickConfig) => {
  return useCallback(
    (event: MouseEvent) => {
      // если нажата просто правая кнопка мыши
      if (event.button === 2) {
        if (!conf.activated) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          conf.service.click(conf.id);
          conf.setActivated(true);
        }
      }
      event.preventDefault();
      const newTab = conf.newTab || event.ctrlKey;

      if (!conf.activated) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        conf.service.click(conf.id);
        sendEvent('General', 'ClickOnResultPage', conf.analytics);
      }
      conf.setActivated(true);
      if (typeof window === 'object') {
        if (newTab) {
          window.open(conf.url, '_blank');
        } else {
          window.location.href = conf.url;
        }
      }
    },
    [conf.service, conf.analytics, conf.id, conf.url, conf.activated]
  );
};

export const useFlatLinkProps = <A extends DescriptionType>(host: string, flat: FlatLink<A>) => {
  const service = new VisibilityService(host);
  const [activated, setActivated] = useState(false);
  const analytics = useMemo(() => serializeDescription(flat.analytics), [flat.analytics]);
  const config = { ...flat, analytics, activated, setActivated, service };
  return {
    href: flat.url,
    onClick: useFlatClick({ ...config, newTab: true }),
    onAuxClick: useFlatClick({ ...config, newTab: true })
  };
};
