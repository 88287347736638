import styles from './flat-position-items.module.css';

import React from 'react';

import { Section, Group, RawPoints } from 'core/utils/flat/flat-position-types';
import { GROUPS, loadPoints, stringifyMetrics } from 'core/utils/flat/flat-positions';

const SectionInfo: React.FC<Section> = (props: Section) => {
  return (
    <>
      <h3 className={styles.section}>
        {props.text}: {stringifyMetrics(props)}
      </h3>
      <ul className={styles.dependencies}>
        {props.dependencies.map((dependency) => (
          <li key={dependency.name}>
            {dependency.comment}: {dependency.value}
          </li>
        ))}
      </ul>
    </>
  );
};

const GroupInfo: React.FC<Group> = (props: Group) => {
  return (
    <>
      <h2 className={styles.group}>
        {props.text}: {stringifyMetrics(props)}
      </h2>
      {props.sections.map((section: Section) => (
        <SectionInfo key={section.name} {...section} />
      ))}
    </>
  );
};

interface RatingInfoProps {
  points: RawPoints[];
}

export const RatingInfo: React.FC<RatingInfoProps> = (props) => {
  const points = React.useMemo(() => loadPoints(GROUPS, props.points), [props.points]);
  return (
    <>
      {points.map((group) => (
        <GroupInfo key={group.name} {...group} />
      ))}
    </>
  );
};
