import styles from './flat-starred-button.module.css';

import StarredIcon from './starred.svg';

import classNames from 'classnames';
import { useCallback, useState } from 'react';

interface FlatFavoriteButtonProps {
  className?: string;
  flatId: number;
  isActive: boolean;
  onAdd: (flatId: number) => Promise<void>;
  onRemove: (flatId: number) => Promise<void>;
}

export const FlatStarredButton = (props: FlatFavoriteButtonProps) => {
  const [isActive, setIsActive] = useState(props.isActive);

  const handleClick = useCallback(async () => {
    if (isActive) {
      setIsActive(false);
      await props.onRemove(props.flatId);
    } else {
      setIsActive(true);
      await props.onAdd(props.flatId);
    }
  }, [isActive]);

  return (
    <button className={classNames(styles.root, props.className, { [styles.active]: isActive })} onClick={handleClick}>
      <StarredIcon />
    </button>
  );
};
