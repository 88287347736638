import get from 'lodash/get';

import { APIService } from 'core/services';
import { StarredPageContext } from 'core/services/context/context';

interface StarredResponse {
  flatId: number;
}

export class StarredService extends APIService {
  public async fetchContext(cookie: string, accept: string): Promise<StarredPageContext> {
    const response = await this.execute('/api/v3/context/bookmarks', 'GET', {
      customHeaders: {
        Cookie: cookie,
        Accept: accept
      }
    });
    return {
      flats: get(response, 'data.flats', {})
    };
  }

  public async add(flatId: number): Promise<StarredResponse> {
    const response = await this.execute('/api/v3/bookmark', 'POST', {
      payload: { flatId },
      withCredentials: true
    });
    return {
      flatId: get(response, 'data.flatId', 0)
    };
  }

  public async remove(flatId: number): Promise<StarredResponse> {
    const response = await this.execute(`/api/v3/bookmark/${flatId}`, 'DELETE', { withCredentials: true });
    return {
      flatId: get(response, 'data.flatId', 0)
    };
  }
}
