import { getBuildingTypeText } from './get-building-type-text';

import { CardFlat, UserFlat } from 'core/entities/flats';

export function getFlatCardPhotoTitle(flat: CardFlat | UserFlat, index = 0) {
  const buildingType = getBuildingTypeText(flat, true);

  const city = flat.city ? flat.city.namePrepPrep : '';

  let subwayStation;
  if ('stations' in flat) {
    subwayStation = flat.stations.length !== 0 ? flat.stations[0].name : '';
  }

  let baseTitle = `${buildingType} ${city}, ${flat.address}`;
  if (subwayStation) {
    baseTitle = `${baseTitle}, м. ${subwayStation}`;
  }

  return index ? `${baseTitle}, - Фото №${index + 1}` : baseTitle;
}
