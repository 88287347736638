/* eslint-disable complexity */
import styles from './flat-card.module.css';

import classNames from 'classnames';
import get from 'lodash/get';
import { useCallback, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import { BEST_ALLOWED_RATING_VALUE, REVIEW_AVERAGE_FOR_CITY } from 'core/constants/review';
import { Currency } from 'core/entities';
import { CardFlat } from 'core/entities/flats';
import { getFlatCardPhotoTitle } from 'core/utils/flat/get-flat-card-photo-title';
import { getPricePerDay } from 'core/utils/flat/get-price-per-day';

import { useStarred } from 'hooks/starred';

import { Checkbox } from 'components/common/checkbox/checkbox';
import { Text, TextColors } from 'components/common/text/text';
import { FlatCardInfo } from 'components/flat/flat-card/flat-card-info/flat-card-info';
import { FlatCardSlider } from 'components/flat/flat-card/flat-card-slider/flat-card-slider';
import { FlatDeletedMask } from 'components/flat/flat-card/flat-deleted-mask/flat-deleted-mask';
import { FlatLink } from 'components/flat/flat-card/flat-link/flat-link';
import { FlatOwnerAvatar } from 'components/flat/flat-card/flat-owner-avatar/flat-owner-avatar';
import { FlatStarredButton } from 'components/flat/flat-card/flat-starred-button/flat-starred-button';
import { FlatPositionInline } from 'components/flat/flat-position-inline/flat-position-inline';

export interface FlatGaData {
  position: {
    column: number;
    row: number;
  };
  filtersEnabled: boolean;
  cityId: number;
  index?: number;
}

interface FlatCardProps {
  className?: string;
  mainHost: string;
  flat: CardFlat;
  currency: Currency;
  isStarred: () => boolean;
  isRetina: Optional<boolean>;
  isMobileDevice: boolean;
  gaData?: FlatGaData;
  isPreview?: boolean;
  dateDuration?: Optional<number>;
  // свойство определяет, нужно ли показывать маску при удалении из закладок
  hasMaskWhenRemove?: boolean;

  onVisibilityChange?: (visibility: boolean, flat: CardFlat) => void;
  onFlatClick?: Optional<(flat: CardFlat) => void>;

  simpleView?: boolean;
  checked?: boolean;
  onToggle?: Optional<(flatId: number) => void>;
}

interface RenderSchemaProps {
  flat: CardFlat;
  currency: Currency;
}

const renderSchema = ({ flat, currency }: RenderSchemaProps) => {
  return (
    <div className={styles.hidden} itemScope itemType='http://schema.org/Hotel'>
      <meta itemProp='name' content={getFlatCardPhotoTitle(flat)} />
      <meta itemProp='priceRange' content={`от ${getPricePerDay(flat.prices, null)} ${currency.entity}/сутки`} />
      <div itemProp='address' itemScope itemType='http://schema.org/PostalAddress'>
        <meta itemProp='name' content={`г. ${flat.city.name}, ${flat.address}`} />
        <meta itemProp='streetAddress' content={flat.city.name} />
        <meta itemProp='addressLocality' content={flat.city.name} />
        {flat.city.region && <meta itemProp='addressRegion' content={flat.city.region.name} />}
        {flat.city.country && <meta itemProp='addressCountry' content={flat.city.country.name} />}
      </div>
      {!!flat.photos && flat.photos.length !== 0 && (
        <>
          <meta itemProp='photo' content={flat.photos[0].thumbs.list} />
          <meta itemProp='image' content={flat.photos[0].thumbs.list} />
        </>
      )}
      {!!flat.reviews.count && !!flat.reviews.average && flat.reviews.average > REVIEW_AVERAGE_FOR_CITY && (
        <div itemProp='aggregateRating' itemScope itemType='https://schema.org/AggregateRating'>
          <meta itemProp='ratingValue' content={flat.reviews.average.toFixed(1)} />
          <meta itemProp='reviewCount' content={flat.reviews.count.toString()} />
          <meta itemProp='bestRating' content={BEST_ALLOWED_RATING_VALUE.toString()} />
        </div>
      )}
    </div>
  );
};

export const FlatCard = ({ simpleView = false, ...props }: FlatCardProps) => {
  const [wasShown, setWasShown] = useState(false);

  const { addToStarred, removeFromStarred, isRemovedFromStarred, errorValue } = useStarred(
    props.mainHost,
    props.hasMaskWhenRemove
  );

  const handleChangeVisibility = useCallback(
    (isVisible: boolean) => {
      if (isVisible && props.onVisibilityChange && !wasShown) {
        props.onVisibilityChange(isVisible, props.flat);
        setWasShown(true);
      }
    },
    [wasShown]
  );

  const handleChangeSelection = useCallback(() => {
    if (props.onToggle) {
      props.onToggle(props.flat.id);
    }
  }, [props.onToggle]);

  const handleFlatClick = () => {
    if (props.onFlatClick) {
      props.onFlatClick(props.flat);
    }
  };

  const schemas = renderSchema({ flat: props.flat, currency: props.currency });

  return (
    <VisibilitySensor scrollCheck={true} resizeCheck={true} onChange={handleChangeVisibility}>
      <li className={classNames(styles.root, props.className, { [styles.preview]: props.isPreview })}>
        {!simpleView && schemas}
        {props.onToggle && (
          <Checkbox
            id={`flat-card-${props.flat.id}`}
            className={styles.checkbox}
            checked={props.checked}
            onToggle={handleChangeSelection}
          />
        )}

        {props.hasMaskWhenRemove && isRemovedFromStarred ? (
          <FlatDeletedMask className={styles.top} flatId={props.flat.id} onReturn={addToStarred} />
        ) : (
          <div className={styles.top}>
            <FlatStarredButton
              className={styles.favoriteBtn}
              flatId={props.flat.id}
              isActive={props.isStarred()}
              onAdd={addToStarred}
              onRemove={removeFromStarred}
            />
            <FlatLink
              mainHost={props.mainHost}
              config={{
                id: props.flat.id,
                url: props.flat.url,
                analytics: {
                  Row: get(props, 'gaData.position.row', 0),
                  Column: get(props, 'gaData.position.column', 0),
                  Filter: get(props, 'gaData.filtersEnabled') ? 'on' : 'off',
                  // TODO: positionInCity пока нет в апи
                  GeneralRatingPos: get(props, 'flat.positionInCity', 0),
                  FilteredRatingPos: get(props, 'gaData.index', 0),
                  CityId: get(props, 'gaData.cityId', 0),
                  URL: typeof window !== 'undefined' && window.location.href
                }
              }}
              ownProps={{
                className: styles.link
              }}
              sendAnalytics
              dateDuration={props.dateDuration}
              onFlatClick={props.onFlatClick ? handleFlatClick : null}
            >
              <FlatCardSlider
                flat={props.flat}
                isRetina={props.isRetina}
                isMobileDevice={props.isMobileDevice}
                simpleView={simpleView}
              />
            </FlatLink>
            {'owner' in props.flat && props.flat.owner.profile.avatar && (
              <FlatOwnerAvatar
                className={styles.avatar}
                ownerId={props.flat.owner.id}
                thumbs={props.flat.owner.profile.avatar.thumbs}
                isRetina={props.isRetina}
              />
            )}
          </div>
        )}
        <FlatPositionInline
          className={styles.adminInfo}
          rating={props.flat.admin_info}
          flat={props.flat}
          mainHost={props.mainHost}
        />
        <FlatCardInfo
          flat={props.flat}
          currency={props.currency}
          duration={props.dateDuration}
          simpleView={simpleView}
        />
        {errorValue && (
          <Text className={styles.error} size={14} color={TextColors.error}>
            {errorValue}
          </Text>
        )}
      </li>
    </VisibilitySensor>
  );
};
