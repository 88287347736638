import styles from './flat-card-slider.module.css';

import classNames from 'classnames';
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import SwiperCore, { EffectFade, Pagination, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PaginationOptions } from 'swiper/types';
import SwiperClass from 'swiper/types/swiper-class';

import { CardFlat } from 'core/entities/flats';
import { getFlatCardPhotoTitle } from 'core/utils/flat/get-flat-card-photo-title';
import { NOOP } from 'core/utils/NOOP';

import { FlatCardPhoto } from 'components/flat/flat-card/flat-card-slider/flat-card-photo/flat-card-photo';

SwiperCore.use([EffectFade, Pagination, Virtual]);

interface FlatCardSliderProps {
  flat: CardFlat;
  isRetina: Optional<boolean>;
  isMobileDevice: boolean;
  isMap?: boolean;
  simpleView?: boolean;
}

const MAX_PHOTOS = 10;
const SLIDE_DELAY = 320;
const START_VISIBLE_BULLETS = 5;
const INDEX_OF_FIRST_CHANGE = Math.ceil(START_VISIBLE_BULLETS / 2);

export const FlatCardSlider = ({ flat, isRetina, isMobileDevice, isMap = false, simpleView }: FlatCardSliderProps) => {
  const paginationClassName = classNames(styles.pagination, {
    [styles.paginationMinBulletsAmount]: flat.photos.length <= INDEX_OF_FIRST_CHANGE
  });

  const [isInit, setIsInit] = useState(false);

  const [geometry, setGeometry] = useState({ left: 0, width: 0 });
  const [preloadPhotos, setPreloadPhotos] = useState(1);
  const [swiperRef, setSwiperRef] = useState<Optional<SwiperClass>>(null);
  const [isTouched, setIsTouched] = useState(isMobileDevice);

  const ref = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);

  const setCurrentGeometry = useCallback(() => {
    if (ref.current) {
      const { left, width } = ref.current.getBoundingClientRect();
      setGeometry({ left, width });
    }
  }, []);

  useEffect(() => {
    setCurrentGeometry();
    setPreloadPhotos(Math.min(flat.photos.length, MAX_PHOTOS));

    if (!isMobileDevice) {
      window.addEventListener('resize', setCurrentGeometry);
    }

    return () => {
      if (!isMobileDevice) {
        window.removeEventListener('resize', setCurrentGeometry);
      }
    };
  }, [isMobileDevice, flat.photos.length, setCurrentGeometry]);

  const handleChangePhoto = useCallback(
    (index: number) => {
      if (swiperRef && index >= 0) {
        swiperRef.slideTo(index, SLIDE_DELAY);
      }
    },
    [swiperRef]
  );

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      if (event.clientX) {
        const percentage = (event.clientX - geometry.left) / geometry.width;
        handleChangePhoto(Math.floor(preloadPhotos * percentage));
      }
    },
    [preloadPhotos, geometry, handleChangePhoto]
  );

  const handleMouseMove = isMobileDevice ? NOOP : onMouseMove;

  const handleMouseOver = useCallback(() => {
    setIsTouched(true);
  }, []);

  const paginationOptions: PaginationOptions = {
    dynamicBullets: true,
    dynamicMainBullets: 3,

    bulletClass: classNames(styles.bullet, 'swiper-pagination-bullet'),
    bulletActiveClass: styles.activeBullet,
    el: paginationRef.current
  };

  const handleInit = useCallback(() => {
    setIsInit(true);
  }, [isInit]);

  return (
    <div className={styles.root} ref={ref} onMouseMove={handleMouseMove} onMouseOver={handleMouseOver}>
      {simpleView && isMobileDevice ? (
        <FlatCardPhoto
          className={styles.simpleView}
          photo={flat.photos[0]}
          title={getFlatCardPhotoTitle(flat)}
          isRetina={isRetina}
          isMap={isMap}
        />
      ) : (
        <Swiper
          className={classNames(styles.swiper, { [styles.swiperOnMap]: isMap })}
          onSwiper={setSwiperRef}
          slidesPerView={1}
          virtual={isMobileDevice}
          pagination={isMobileDevice ? paginationOptions : false}
          effect={isMobileDevice ? 'slide' : 'fade'}
          onInit={handleInit}
        >
          <div className={paginationClassName} ref={paginationRef} />
          {isInit && isTouched && flat.photos.length !== 0 ? (
            flat.photos.map((photo, index) => (
              <SwiperSlide className={styles.slide} key={photo.id} virtualIndex={index}>
                <FlatCardPhoto
                  photo={photo}
                  title={getFlatCardPhotoTitle(flat, index)}
                  isRetina={isRetina}
                  isMap={isMap}
                />
              </SwiperSlide>
            ))
          ) : (
            <FlatCardPhoto
              photo={flat.photos[0]}
              title={getFlatCardPhotoTitle(flat)}
              isRetina={isRetina}
              isMap={isMap}
            />
          )}
        </Swiper>
      )}
    </div>
  );
};
