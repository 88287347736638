import { useEffect, useState } from 'react';

import { isRetinaDisplay } from 'core/utils/photos/is-retina-display';

export const useRetinaDisplay = () => {
  const [isRetina, setIsRetina] = useState<Optional<boolean>>(null);

  useEffect(() => {
    setIsRetina(isRetinaDisplay());
  }, []);

  return isRetina;
};
