import styles from './flat-owner-avatar.module.css';

import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { AvatarThumbs } from 'core/entities/photos';

import CustomLink from 'components/common/link/link';

interface FlatOwnerAvatarProps {
  className?: string;
  ownerId: number;
  thumbs: AvatarThumbs;
  isRetina: Optional<boolean>;
}

interface ImageProps {
  className?: string;
  image?: string;
}

const Image = (props: ImageProps) => {
  return (
    <div
      className={classNames(styles.img, props.className)}
      style={{
        backgroundColor: '#888888',
        backgroundImage: props.image ? `url(${props.image})` : 'none'
      }}
    />
  );
};

export const FlatOwnerAvatar = ({ className, ownerId, thumbs, isRetina }: FlatOwnerAvatarProps) => {
  const [image, setImage] = useState('');
  const { asPath } = useRouter();
  const ownerPagePath = `/owner/${ownerId}/`;

  useEffect(() => {
    if (isRetina) {
      setImage(thumbs.mini_x2);
    } else {
      setImage(thumbs.mini);
    }
  }, [isRetina]);

  if (asPath.includes(ownerPagePath)) {
    return null;
  }

  return (
    <CustomLink className={classNames(styles.root, styles.link)} href={`/owner/${ownerId}/`}>
      <Image className={classNames(styles.img, className)} image={image} />
    </CustomLink>
  );
};
