import styles from './flat-deleted-mask.module.css';

import classNames from 'classnames';
import { useCallback } from 'react';

import { Button } from 'components/common/button/button';
import { Text } from 'components/common/text/text';

interface FlatDeletedMaskProps {
  className?: string;
  flatId: number;
  onReturn: (flatId: number) => Promise<void>;
}

export const FlatDeletedMask = ({ className, flatId, onReturn }: FlatDeletedMaskProps) => {
  const onClick = useCallback(async () => {
    await onReturn(flatId);
  }, []);

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.wrapper}>
        <Text className={styles.text} size={16}>
          Объявление удалено из закладок
        </Text>
        <Button text='Вернуть' size='sm' variant='outline' onClick={onClick} />
      </div>
    </div>
  );
};
