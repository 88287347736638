import styles from './flat-card-photo.module.css';

import classNames from 'classnames';

import { DEFAULT_PHOTO_COLOR } from 'core/constants/default-values';
import { Photo } from 'core/entities/photos';

interface FlatCardPhotoProps {
  className?: string;
  photo: Photo;
  title: string;
  isRetina: Optional<boolean>;
  isMap?: boolean;
}

export const FlatCardPhoto = ({ photo, title, isRetina, isMap = false, className }: FlatCardPhotoProps) => {
  if (!photo || !photo.thumbs) {
    return (
      <div
        className={classNames(styles.root, { [styles.onMap]: isMap }, className)}
        style={{
          backgroundColor: DEFAULT_PHOTO_COLOR
        }}
      >
        <img
          className={styles.image}
          src={'/s/modern/no-photo/common/no-photo.svg'}
          alt={title}
          title={title}
          loading='lazy'
        />
      </div>
    );
  }

  const imageSrc = typeof isRetina !== 'object' && isRetina ? photo.thumbs.listRetina : photo.thumbs.list;

  return (
    <div
      className={classNames(styles.root, { [styles.onMap]: isMap }, className)}
      style={{
        backgroundColor: photo.averageColor ? `#${photo.averageColor}` : DEFAULT_PHOTO_COLOR
      }}
    >
      {photo && <img className={styles.image} src={imageSrc} alt={title} title={title} loading='lazy' />}
    </div>
  );
};
