import * as Sentry from '@sentry/nextjs';

import { APIService } from 'core/services/index';

export interface VisibilityPayload {
  addIds: number[];
  addPositions: number[];
  cityId: number;
  filtersEnabled: boolean;
}

export class VisibilityService extends APIService {
  constructor(host: Optional<string>) {
    super(`chronicle.${host ? host : 'kvartirka.com'}`);
  }

  async send(payload: VisibilityPayload): Promise<void> {
    try {
      await this.execute('/api/visibility', 'POST', {
        payload: { ...payload },
        customHeaders: { 'Content-Type': 'application/json; charset=UTF-8' }
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  async click(flatId: number) {
    try {
      await this.execute(`/api/flats/${flatId}/clicks`, 'POST');
    } catch (e) {
      Sentry.captureException(e);
    }
  }
}
