export enum Formatter {
  plain,
  bool,
  time,
  date,
  seconds,
  percentage
}

export interface DependencyConfig {
  name: string;
  comment: string;
  formatter: Formatter;
}

export interface Metrics {
  min?: number;
  max?: number;
  multiplier?: boolean;
}

export interface SectionConfig extends Metrics {
  name: string;
  text: string;
  dependencies: DependencyConfig[];
}

export interface GroupConfig {
  name: string;
  text: string;
  sections: SectionConfig[];
}

export interface RawPoints {
  value: number;
  section: string;
  props: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface ValueHolder<T> {
  value: T;
}

export type Dependency = DependencyConfig & ValueHolder<string | null>;

export interface Section extends ValueHolder<number>, SectionConfig {
  dependencies: Dependency[];
}

export interface Group extends GroupConfig, Metrics, ValueHolder<number> {
  sections: Section[];
}
