import get from 'lodash/get';

import { APIService } from 'core/services';
import { RawPoints } from 'core/utils/flat/flat-position-types';

export class FlatPositionService extends APIService {
  public async fetchCaclulcationData(flatKey: number): Promise<RawPoints[]> {
    const response = await this.execute(`/api/control/flats/${flatKey}/rating`, 'GET', {});
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return get(response, 'data.rating.points', []) as RawPoints[];
  }
}
