import { AnchorHTMLAttributes, MouseEvent, ReactNode, useCallback } from 'react';

import { useFlatLinkProps, FlatLink as LinkConfig } from 'hooks/analytics';
import { BrowserFamily, detectBrowserFamily } from 'hooks/use-browser';

interface FlatLinkProps {
  mainHost: string;
  config: LinkConfig;
  sendAnalytics: boolean;
  ownProps: AnchorHTMLAttributes<HTMLAnchorElement>;
  children?: ReactNode;
  className?: string;
  dateDuration?: Optional<number>;
  onFlatClick?: Optional<() => void>;
}

export const FlatLink = (props: FlatLinkProps) => {
  const hook = useFlatLinkProps(props.mainHost, props.config);
  const linkProps = { ...props.ownProps };

  const handleFlatClick = (event: MouseEvent) => {
    if (props.dateDuration) {
      return;
    }

    if (props.onFlatClick) {
      event.preventDefault();
      event.stopPropagation();
      props.onFlatClick();
    }
  };

  const catchWheelClick = useCallback(
    (event: MouseEvent, browserName?: BrowserFamily) => {
      const browserFamily = detectBrowserFamily();

      // из-за разной обработки клика колесика (Safari и на mouseUp и на mouseDown, прочие браузеры только на mouseDown)
      if (browserFamily !== browserName && event.button === 1 && props.onFlatClick) {
        event.preventDefault();
        event.stopPropagation();

        handleFlatClick(event);
      }
    },
    [props.onFlatClick]
  );

  const handleMouseUp = (event: MouseEvent) => {
    catchWheelClick(event);
  };

  const handleMouseDown = (event: MouseEvent) => {
    catchWheelClick(event, BrowserFamily.safari);
  };

  if (props.sendAnalytics) {
    Object.assign(linkProps, hook);
  } else {
    linkProps.href = props.config.url;
  }

  return (
    <a
      {...linkProps}
      className={props.className}
      onClick={handleFlatClick}
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
      target='_blank'
    >
      {props.children}
    </a>
  );
};
