import { useCallback, useRef, useState } from 'react';

import { StarredService } from 'core/services/context/starred';

export const useStarred = (mainHost: string, hasMaskWhenRemove = false) => {
  const starredService = useRef(new StarredService(mainHost));
  const [isRemovedFromStarred, setIsRemovedFromStarred] = useState(false);
  const [errorValue, setErrorValue] = useState('');

  const addToStarred = useCallback(
    async (flatId: number) => {
      try {
        setErrorValue('');
        await starredService.current.add(flatId);
        if (hasMaskWhenRemove) {
          setIsRemovedFromStarred(false);
        }
      } catch (err) {
        setErrorValue('Объявление не добавлено, повторите');
      }
    },
    [starredService]
  );

  const removeFromStarred = useCallback(
    async (flatId: number) => {
      try {
        setErrorValue('');
        await starredService.current.remove(flatId);
        if (hasMaskWhenRemove) {
          setIsRemovedFromStarred(true);
        }
      } catch (err) {
        setErrorValue('Объявление не удалено, повторите');
      }
    },
    [starredService]
  );

  return { addToStarred, removeFromStarred, isRemovedFromStarred, errorValue };
};
