import * as Types from './flat-position-types';

import * as Sentry from '@sentry/browser';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

export enum Formatter {
  plain,
  bool,
  time,
  date,
  seconds,
  percentage
}

export interface Metrics {
  min?: number;
  max?: number;
  multiplier?: boolean;
}

export interface DependencyConfig {
  name: string;
  comment: string;
  formatter: Formatter;
  value?: number;
}

export interface SectionConfig extends Metrics {
  name: string;
  text: string;
  value?: number;
  dependencies: DependencyConfig[];
}

export interface GroupConfig {
  name: string;
  text: string;
  sections: SectionConfig[];
}

export const GROUPS: GroupConfig[] = [
  {
    name: 'auction',
    text: 'Аукцион',
    sections: [
      {
        name: 'auction__bid',
        text: 'Множитель ставки',
        multiplier: true,
        min: 1,
        dependencies: [
          {
            name: 'bid',
            formatter: Formatter.plain,
            comment: 'Ставка'
          }
        ]
      }
    ]
  },
  {
    name: 'booking',
    text: 'Бронирование',
    sections: [
      {
        name: 'booking__accepted_requests',
        text: 'Подтверждение заявок',
        min: -50,
        max: 50,
        dependencies: [
          { name: 'total', formatter: Formatter.plain, comment: 'Учтено последних заявок (до 30) по объявлению' },
          { name: 'accepted', formatter: Formatter.plain, comment: 'Из них принято' }
        ]
      },
      {
        name: 'booking__expired_requests',
        text: 'Необработанные заявки',
        min: -200,
        max: 0,
        dependencies: [
          { name: 'total', formatter: Formatter.plain, comment: 'Учтено последних заявок (до 30) по хозяину' },
          { name: 'expired', formatter: Formatter.plain, comment: 'Из них не обработано' }
        ]
      },
      {
        name: 'booking__instant_booking',
        text: 'Мгновенное бронирование',
        min: 0,
        max: 100,
        dependencies: [{ name: 'instant_booking', formatter: Formatter.bool, comment: 'Включено у хозяина' }]
      },
      {
        name: 'booking__cancels',
        text: 'Отмены бронирования',
        min: -300,
        max: 0,
        dependencies: [
          { name: 'total', formatter: Formatter.plain, comment: 'Всего оплат в роли хозяина' },
          { name: 'cancelled', formatter: Formatter.plain, comment: 'Из них отменено по вине хозяина' },
          { name: 'cancelled_by_moderator', formatter: Formatter.plain, comment: 'Из них отменено от Квартирки' }
        ]
      }
    ]
  },
  {
    name: 'photos',
    text: 'Фотографии',
    sections: [
      {
        name: 'photos__count',
        text: 'За количество',
        min: 0,
        max: 30,
        dependencies: [
          {
            name: 'count',
            formatter: Formatter.plain,
            comment: 'Число фоток'
          }
        ]
      },
      {
        name: 'photos__size',
        text: 'За медианный размер',
        min: 0,
        max: 10,
        dependencies: [
          {
            name: 'median_size',
            formatter: Formatter.plain,
            comment: 'Медианный размер большей стороны'
          }
        ]
      },
      {
        name: 'photos__last_partial_update',
        text: 'Частичное обновление',
        min: 0,
        max: 20,
        dependencies: [
          {
            name: 'last_update',
            formatter: Formatter.time,
            comment: 'Самая свежая фотка добавлена'
          }
        ]
      },
      {
        name: 'photos__last_total_update',
        text: 'Обновление большинства',
        min: 0,
        max: 10,
        dependencies: [
          {
            name: 'last_update',
            formatter: Formatter.date,
            comment: 'Медианная дата обновления фоток'
          }
        ]
      }
    ]
  },
  {
    name: 'calendar',
    text: 'Календарь занятости',
    sections: [
      {
        name: 'calendar__last_update',
        text: 'За обновление дат',
        min: 0,
        max: 200,
        dependencies: [
          {
            name: 'sync',
            formatter: Formatter.bool,
            comment: 'Синхронизация дат'
          },
          {
            name: 'last_update',
            formatter: Formatter.time,
            comment: 'Обновление вручную'
          },
          {
            name: 'created',
            formatter: Formatter.time,
            comment: 'Создание объявления'
          }
        ]
      },
      {
        name: 'calendar__busy_days_count',
        text: 'Заполняемость в ближайшие 120 суток',
        min: 0,
        max: 30,
        dependencies: [
          {
            name: 'bonus_points',
            formatter: Formatter.plain,
            comment: 'Баллы'
          }
        ]
      }
    ]
  },
  {
    name: 'prices',
    text: 'Календарь цен',
    sections: [
      {
        name: 'prices__last_update',
        text: 'За обновление цен',
        min: 0,
        max: 150,
        dependencies: [
          {
            name: 'sync',
            formatter: Formatter.bool,
            comment: 'Синхронизация цен'
          },
          {
            name: 'last_update',
            formatter: Formatter.time,
            comment: 'Обновление вручную'
          },
          {
            name: 'has_actual_price',
            formatter: Formatter.bool,
            comment: 'Есть хотя бы одна цена на предстоящие даты'
          }
        ]
      }
    ]
  },
  {
    name: 'reviews',
    text: 'Отзывы',
    sections: [
      {
        name: 'reviews__origin',
        text: 'Отзывы на это объявление',
        dependencies: []
      },
      {
        name: 'reviews__others',
        text: 'Отзывы на другие объявления хозяина',
        dependencies: []
      }
    ]
  },
  {
    name: 'answers',
    text: 'Скорость ответов',
    sections: [
      {
        name: 'answer_stats__delay',
        text: 'Время ответа',
        min: 0,
        max: 50,
        dependencies: [
          {
            name: 'delay',
            formatter: Formatter.seconds,
            comment: 'Средняя скорость ответа'
          },
          {
            name: 'percentage',
            formatter: Formatter.percentage,
            comment: 'Процент ответов'
          }
        ]
      },
      {
        name: 'answer_stats__app',
        text: 'Недавно пользовался приложением',
        min: 0,
        max: 20,
        dependencies: [
          {
            name: 'last_visit',
            formatter: Formatter.date,
            comment: 'Последнее использование приложения'
          }
        ]
      },
      {
        name: 'has_telegram',
        text: 'Подписан на ТГ',
        min: 0,
        max: 10,
        dependencies: []
      },
      {
        name: 'has_social_profiles',
        text: 'Подключен Я или ВК',
        min: 0,
        max: 20,
        dependencies: []
      }
    ]
  },
  {
    name: 'profile',
    text: 'Анкета',
    sections: [
      {
        name: 'profile__features',
        text: 'Галочки',
        min: 0,
        max: 10,
        dependencies: []
      },
      {
        name: 'profile__floors',
        text: 'Этаж и тип жилья',
        min: 0,
        max: 5,
        dependencies: [
          {
            name: 'building_type',
            formatter: Formatter.plain,
            comment: 'Тип жилья'
          },
          {
            name: 'floor',
            formatter: Formatter.plain,
            comment: 'Этаж'
          },
          {
            name: 'total_floor',
            formatter: Formatter.plain,
            comment: 'Высота здания'
          }
        ]
      },
      {
        name: 'profile__last_update',
        text: 'Обновление',
        min: 0,
        max: 10,
        dependencies: [
          {
            name: 'last_update',
            formatter: Formatter.time,
            comment: 'Последнее изменение'
          }
        ]
      },
      {
        name: 'profile__description',
        text: 'Описание',
        min: 0,
        max: 5,
        dependencies: []
      },
      {
        name: 'profile__username',
        text: 'Имя',
        min: 0,
        max: 5,
        dependencies: []
      },
      {
        name: 'bookmarks',
        text: 'Добавление объявления в избранное гостями',
        min: 0,
        max: 50,
        dependencies: []
      }
    ]
  },
  {
    name: 'bonus',
    text: 'Личные бонусы',
    sections: [
      {
        name: 'bonus__owner_age',
        text: 'За каждый год после регистрации',
        min: 0,
        dependencies: [
          {
            name: 'registration',
            formatter: Formatter.time,
            comment: 'Дата регистрации'
          }
        ]
      },
      {
        name: 'bonus__owner_points',
        text: 'Бонус или штраф из админки',
        dependencies: [
          {
            name: 'bonus_points',
            formatter: Formatter.plain,
            comment: 'Баллы'
          }
        ]
      },
      {
        name: 'bonus__rookie',
        text: 'Бонус новичкам',
        min: 0,
        max: 250,
        dependencies: [
          {
            name: 'bonus_points',
            formatter: Formatter.plain,
            comment: 'Баллы'
          }
        ]
      }
    ]
  },
  {
    name: 'bonus_kvartirka',
    text: 'Бонусы от Квартирки',
    sections: [
      {
        name: 'bonus__has_bookmark',
        text: 'Если главный аккаунт добавил в избранное',
        min: 0,
        max: 100,
        dependencies: [
          {
            name: 'bonus_points',
            formatter: Formatter.plain,
            comment: 'Баллы'
          }
        ]
      }
    ]
  }
];

function sum(first?: number, second?: number): number | undefined {
  // eslint-disable-next-line
  return isNil(first) || isNil(second) ? undefined : first + second;
}

function uniteMetrics(first: Types.Metrics, second: Types.Metrics): Types.Metrics {
  if (!!first.multiplier === !second.multiplier) {
    return {};
  }
  return { multiplier: first.multiplier, min: sum(first.min, second.min), max: sum(first.max, second.max) };
}

function sumMetrics(metrics: Types.Metrics[]): Types.Metrics {
  if (metrics.length === 0) {
    return {};
  }
  if (metrics.length === 1) {
    return { min: metrics[0].min, max: metrics[0].max, multiplier: metrics[0].multiplier };
  }
  return metrics.reduce(uniteMetrics);
}

function formatDuration(duration: number) {
  let result = `${duration} cек`;

  if (Math.floor(duration / 3600) > 1) {
    result = `${Math.round(duration / 3600)} ч`;
  } else if (Math.floor(duration / 60) > 1) {
    result = `${Math.round(duration / 60)} мин`;
  }

  return result;
}

function stringifyProps(formatter: Types.Formatter, value: number | string): string | null {
  if (!isNil(value)) {
    try {
      switch (formatter) {
        case Types.Formatter.percentage:
          return parseFloat(value as string).toLocaleString('ru-RU', { style: 'percent', maximumFractionDigits: 1 });
        case Types.Formatter.date:
          return format(new Date(value), 'd MMM yyyy', { locale: ru });
        case Types.Formatter.seconds:
          return formatDuration(parseInt(value as string, 10));
        case Types.Formatter.time:
          return format(new Date(value), 'd MMM yyyy HH:mm', { locale: ru });
        case Types.Formatter.bool:
          return value ? 'да' : 'нет';
        case Types.Formatter.plain:
          return value.toString();
        default:
          return null;
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }
  return null;
}

export function loadPoints(config: Types.GroupConfig[], points: Types.RawPoints[]): Types.Group[] {
  const mapping: { [key: string]: Types.RawPoints } = points.reduce(
    (acc, current) => ({ ...acc, [current.section]: current }),
    {}
  );
  const result: Types.Group[] = [];
  config.forEach((group) => {
    const metrics = sumMetrics(group.sections.filter((section) => mapping[section.name]));
    const sections: Types.Section[] = [];
    let groupValue = 0;
    group.sections.forEach((section) => {
      const sectionPoints = mapping[section.name];
      if (sectionPoints) {
        if (!section.multiplier) {
          groupValue = groupValue + sectionPoints.value;
        } else {
          groupValue = Math.max(groupValue, 1) * sectionPoints.value;
        }
        sections.push({
          ...section,
          value: sectionPoints.value,
          dependencies: section.dependencies
            .map((dependency) => ({
              ...dependency,
              value: stringifyProps(dependency.formatter, get(sectionPoints.props, dependency.name) as number | string)
            }))
            .filter((dependency) => !isNil(dependency.value))
        });
      }
    });
    if (sections.length) {
      result.push({ ...group, ...metrics, sections, value: groupValue });
    }
  });
  return result;
}

export function stringifyMetrics(metrics: Types.Metrics & Types.ValueHolder<number>): string {
  const prefix = metrics.multiplier ? 'x' : '';
  const lowBorder = !metrics.min ? '' : `${prefix}${metrics.min}…`;
  const suffix = isNil(metrics.max) ? '' : ` из ${lowBorder}${prefix}${metrics.max}`;
  return `${prefix}${metrics.value}${suffix}`;
}
